/*$h1-font-size
$h2-font-size
$h3-font-size
$h4-font-size
$h5-font-size*/
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body, html {
  font-family: 'Raleway', sans-serif;
  height: 100%;
  margin: 0; }

.wrapper {
  min-height: 100%;
  padding-bottom: 450px; }

::-moz-selection {
  background-color: rgba(226, 62, 82, 0.5); }

::selection {
  background-color: rgba(226, 62, 82, 0.5); }

a {
  color: #e33d51; }
  a:hover {
    color: #343a40; }

.nav-pills .nav-link {
  border-radius: 0; }

.bg-foss-red {
  color: #FFF;
  background-color: #e33d51 !important; }

.text-foss-red {
  color: #e33d51 !important; }

.foss-border-top {
  border-top: 3px solid #e33d51 !important; }

.grey-links a {
  color: #6e777f;
  text-decoration: none; }
  .grey-links a:hover {
    text-decoration: none;
    color: #272a2d; }

button:focus {
  outline: transparent; }

#content {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }

#social-media {
  border-top: 1px solid #bdbdbd; }

.alert {
  position: relative;
  padding: .75rem 0;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem; }

.alert-success {
  color: #155724;
  border: 0;
  background: transparent; }

.alert-danger {
  color: #e33d51;
  border: 0;
  background: transparent; }

#topnav {
  /* iPhone 5S and below */ }
  #topnav.navbar {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: .25rem; }
  #topnav .navbar-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
  #topnav .navbar-nav .nav-link {
    font-size: .95rem;
    font-weight: 400;
    padding-right: 1rem;
    color: #FFF; }
  @media (max-width: 359.98px) {
    #topnav .navbar-nav .nav-link {
      font-size: .85rem;
      font-weight: 400;
      padding-right: 1rem;
      color: #fff; } }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border: 0; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border: 0; }

.dropdown-item.active, .dropdown-item :active {
  color: #e33d51;
  text-decoration: none;
  background-color: #f8f9fa; }
  .dropdown-item.active:hover, .dropdown-item :active:hover {
    color: #000;
    background-color: #FFF; }

.dropdown-item.active, .dropdown-item:active {
  color: #e33d51;
  text-decoration: none;
  background-color: #f8f9fa; }

@media (min-width: 768px) {
  .navbar-logo {
    width: 225px;
    padding: 11px 0; } }

@media (max-width: 991.98px) {
  .dropdown-menu {
    border: 0;
    border-radius: 0; } }

@media (min-width: 992px) {
  /*.dropdown:hover>.dropdown-menu {
    display: block;
  }*/
  .dropdown-menu {
    position: absolute;
    top: 129%;
    left: 0;
    /* -280px */
    z-index: 5000;
    display: none;
    float: left;
    min-width: 25rem;
    padding: 0;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: #ffffff;
    text-align: left;
    list-style: none;
    background-color: rgba(52, 58, 64, 0.9);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0; }
    .dropdown-menu.bounceInRight {
      animation-name: none; }
  .dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #ffffff;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0; } }

@media (max-width: 767.58px) {
  .navbar-logo {
    width: 200px;
    padding: 11px 0; } }

/* Navbar */
.bg-light {
  background-color: #FFF !important; }

/* Navbar links */
.navbar-light .navbar-nav .nav-link {
  color: #bdbdbd;
  font-weight: 600;
  text-transform: uppercase; }

.navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show > .nav-link {
  color: #e33d51; }

.media .media-body figure iframe {
  max-width: 100% !important; }

.media .media-left {
  margin-right: 1rem; }
  .media .media-left img {
    width: 64px;
    height: auto; }

.carousel-item:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(20, 20, 35, 0.6); }

.carousel-indicators li {
  position: relative;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 55px;
  height: 8px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #FFF;
  border-radius: 2px; }

.carousel-indicators .active {
  background-color: #fff; }

.carousel-caption h1 {
  font-weight: 600; }

/* iPhone 5S and below */
@media (max-width: 359.98px) {
  .carousel-item {
    height: auto;
    min-height: 300px;
    background: no-repeat center center scroll;
    background-size: cover; }
  .carousel-caption {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    padding-top: 10vh;
    padding-bottom: 20px;
    color: #fff;
    text-align: left; } }

/* iPhone 6/7/8 and landscape */
@media (min-width: 360px) and (max-width: 767.98px) {
  .carousel-item {
    height: auto;
    min-height: 300px;
    background: no-repeat center center scroll;
    background-size: cover; }
  .carousel-caption {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    padding-top: 20vh;
    padding-bottom: 5vh;
    color: #fff;
    text-align: left; }
    .carousel-caption h1 {
      font-size: 2.25rem; } }

/* iPad */
@media (min-width: 768px) and (max-width: 991.98px) {
  .carousel-item {
    height: auto;
    min-height: 300px;
    padding-bottom: 130px;
    background: no-repeat center center scroll;
    background-size: cover; }
  .carousel-caption {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    padding-top: 5vh;
    padding-bottom: 20px;
    color: #fff;
    text-align: left; }
    .carousel-caption h1 {
      font-size: 3rem; }
    .carousel-caption p {
      line-height: 1.75;
      font-size: 1.15rem; } }

/* Desktop */
@media (min-width: 992px) {
  .carousel-item {
    height: 50vh;
    min-height: 300px;
    background: no-repeat center center scroll;
    background-size: cover; }
  .carousel-caption {
    position: absolute;
    top: 10vh;
    right: 0;
    bottom: 10vh;
    left: 0;
    z-index: 10;
    color: #fff;
    text-align: left; }
    .carousel-caption h1 {
      font-size: 3.25rem; }
    .carousel-caption p {
      line-height: 1.75;
      font-size: 1.25rem; } }

.btn-foss {
  color: #fff;
  background-color: #e33d51;
  border-color: #cf1e33; }
  .btn-foss:hover {
    color: #fff;
    background-color: #111;
    border-color: black; }

#contact .form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px dashed #343a40;
  border-radius: 0;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

#contact .btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 1rem 5.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: -0.75rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

.entry-title {
  line-height: 1.5em;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 800;
  color: #111;
  position: relative; }

.entry-title:after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 50%;
  width: 70px;
  border: 2px solid #d0d0d0;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  transform: translateX(-50%); }

.entry-description {
  font-size: 1rem;
  font-weight: 600;
  color: #111; }

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #e33d51; }

.modal-header .close {
  font-size: 18px;
  padding: 1rem;
  margin: -0.5rem -0.5rem -1rem auto; }

.modal-body {
  /* 100% = dialog height, 120px = header + footer */
  max-height: 450px;
  overflow-y: scroll; }

#category .category-icon {
  width: 64px;
  height: 64px; }

.category-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #111; }

.category-intro {
  line-height: 1.75;
  font-size: .95rem;
  font-weight: 400;
  color: #111; }

.category-description {
  font-size: 1rem;
  font-weight: 600;
  color: #111; }

.category-content a {
  color: #e33d51; }

.category-read {
  font-weight: 600;
  color: #e33d51; }
  .category-read:hover {
    color: #cf1e33;
    text-decoration: none; }

.employee {
  height: 100%; }
  .employee .employee-name {
    line-height: 1.5em;
    font-size: 1.25rem;
    text-transform: uppercase;
    font-weight: 800;
    text-align: center;
    color: #FFF;
    position: relative;
    margin-bottom: 2.5rem; }
  .employee .employee-name:after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    width: 70px;
    border: 2px solid #d0d0d0;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    transform: translateX(-50%); }
  .employee .employee-image {
    margin: 0 15px -60px; }
    .employee .employee-image img {
      box-shadow: 0px 5px 5px #222;
      border-bottom: 5px solid #d0d0d0; }
  @media (max-width: 767.91px) {
    .employee .employee-info {
      background: #343a40;
      color: #FFF;
      padding: 5rem 2.5rem 2.5rem;
      border-radius: 15px; }
      .employee .employee-info a {
        color: #e33d51; } }
  @media (min-width: 768px) {
    .employee .employee-info {
      background: #343a40;
      color: #FFF;
      padding: 5rem 2.5rem 2.5rem;
      border-radius: 15px;
      min-height: 75%; }
      .employee .employee-info a {
        color: #e33d51; } }

#bottom-page {
  margin-top: -450px; }

#footer {
  position: relative;
  float: left;
  display: block;
  width: 100%;
  margin-right: 0; }
  #footer a {
    color: #FFF; }
    #footer a:hover {
      color: #e96a79;
      text-decoration: none; }
  #footer .footer {
    width: 100%;
    height: 450px;
    background: #bdbdbd;
    border-top: 1px solid #bdbdbd; }
    @media (min-width: 992px) {
      #footer .footer .footer-overlay {
        color: #FFF;
        background-color: rgba(52, 58, 64, 0.9);
        height: 450px;
        width: 500px;
        /* 38% */
        z-index: 3232;
        top: 0;
        left: 0;
        position: absolute;
        padding-right: 3.5rem; }
        #footer .footer .footer-overlay .footer-overlay-content {
          margin-top: 5.5rem;
          margin-right: 0;
          margin-bottom: 2rem;
          margin-left: 6.5rem;
          /*padding-top: 5rem;
          padding-right: 0;
          padding-left: 5rem;
          padding-bottom: 2rem;*/ } }
    @media (max-width: 991.91px) {
      #footer .footer .footer-overlay {
        color: #FFF;
        background-color: rgba(52, 58, 64, 0.9);
        height: auto;
        width: 100%;
        z-index: 3232;
        top: 0;
        left: 0;
        position: relative; }
        #footer .footer .footer-overlay .footer-overlay-content {
          padding-top: 2.5rem;
          padding-right: 2rem;
          padding-left: 2rem;
          padding-bottom: 2rem; } }
  #footer #map {
    height: 100%;
    width: 100%; }

.image-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  transition: .5s ease;
  background: transparent; }
  .image-overlay:hover {
    background: rgba(0, 0, 0, 0.5); }

.figure-img {
  margin-bottom: 0;
  line-height: 1; }

.figure-caption {
  color: #bdbdbd;
  padding: 1rem; }
  .figure-caption p {
    margin-bottom: 0; }

@media (max-width: 991.98px) {
  .media-video-content {
    margin-top: 1rem; } }
